import React, { useState, useEffect } from "react";
import DoughnutChart from "../components/DoughnutChart";
import axios from "axios";
import { apiKey } from "../config/config";

export default function Dashboard({ lang, dir }) {
  const [users, setUsers] = useState();
  const [investors, setInvestors] = useState();
  const [customer, setCustomer] = useState();
  const [cityInvestors, setCityInvestors] = useState([]);
  const [dealers, setDealers] = useState();
  const [guarnator, setGuarantor] = useState();
  const [branchId, setBranchId] = useState();
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    try {
      axios
        .get(apiKey + "/get-users-no/" + branchId)
        .then((res) => {
          setUsers(res.data.result[0].total_users);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(apiKey + "/get-total-dealers")
        .then((res) => {
          setDealers(res.data.result[0].total_dealers);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(apiKey + "/get-investors-no/" + branchId)
        .then((res) => {
          setInvestors(res.data.result[0].total_investors);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(apiKey + "/get-guarantors-no/" + branchId)
        .then((res) => {
          setGuarantor(res.data.result[0].total);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(apiKey + "/get-customers-no")
        .then((res) => {
          setCustomer(res.data.result[0].total_customers);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(apiKey + "/get-city-investors/" + branchId)
        .then((res) => {
          setCityInvestors(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);

  useEffect(() => {
    const storedUser = JSON.parse(
      JSON.parse(sessionStorage.getItem("aman-admin")).admin
    );
    if (storedUser) {
      setUserRole(storedUser.role);
    }
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Dashboard || "Dashboard"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
            </ol>
          </nav>
        </div>
        <div className="card-body pt-3">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <button className="nav-link active">
                {lang.Overview || "Overview"}
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link">
                {lang.Task || "Task"} <span className="tabicon">7</span>
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link">
                {lang.Documents || "Documents"}{" "}
                <span className="tabicon">99+</span>
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link">
                {lang.Optional_Options || "Optional options"}{" "}
                <span className="tabicon">99+</span>
              </button>
            </li>
            <li className="nav-item">
              <button className="nav-link">{lang.Reports || "Reports"}</button>
            </li>
          </ul>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-3 col-md-3">
                  <div className="card info-card sales-card ">
                    <div className="card-body">
                      <h5 className="card-title">
                        <span>{lang.User_Total || "User Total"}</span>
                      </h5>

                      <div className="d-flex align-items-center justify-content-between">
                        <p className="dashicon">{users}</p>
                        {/* <p className="dashpercent">+2.5%</p> */}
                      </div>
                    </div>
                  </div>
                </div>

                {userRole === 'super' && <div className="col-xxl-3 col-md-3">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        <span>{lang.Dealers || "Dealers"}</span>
                      </h5>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="dashicon">{dealers}</p>
                      </div>
                    </div>
                  </div>
                </div>}

                <div className="col-xxl-3 col-md-3">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        {" "}
                        <span>{lang.Total_Investors || "Total Investors"}</span>
                      </h5>

                      <div className="d-flex align-items-center justify-content-between">
                        <p className="dashicon">{investors}</p>
                        {/* <p className="dashpercent">+2.5%</p> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-md-3">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        {" "}
                        <span>{lang.Guarantors || "Guarantors"}</span>
                      </h5>

                      <div className="d-flex align-items-center justify-content-between">
                        <p className="dashicon">{guarnator}</p>
                        {/* <p className="dashpercent">+2.5%</p> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card">
                    <div className="filter">
                      <a className="icon" href="/" data-bs-toggle="dropdown">
                        <i className="bi bi-three-dots"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>{lang.Filter || "Filter"}</h6>
                        </li>

                        <li>
                          <a className="dropdown-item" href="/">
                            {lang.Today || "Today"}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            {lang.This_Month || "This Month"}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            {lang.This_Year || "This Year"}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="card-body">
                      <h5 className="card-title">
                        {lang.Recent_Investment_Pool ||
                          "Recent Investment Pool"}
                      </h5>

                      <div>
                        <DoughnutChart lang={lang} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="card top-selling overflow-auto">
                    <div className="filter">
                      <a className="icon" href="/" data-bs-toggle="dropdown">
                        <i className="bi bi-three-dots"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>{lang.Filter || "Filter"}</h6>
                        </li>

                        <li>
                          <a className="dropdown-item" href="/">
                            {lang.Today || "Today"}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            {lang.This_Month || "This Month"}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            {lang.This_Year || "This Year"}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="card-body pb-3">
                      <h5 className="card-title">
                        {lang.Investor_by_state || "Investors by city"}
                      </h5>

                      <table className="table table-borderless">
                        <tbody>
                          {cityInvestors.map((investor, index) => (
                            <tr key={index}>
                              <td col-span="8">{investor.name_en}</td>
                              {/* <td>27.5%</td> */}
                              <td>{investor.investor_count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <a
        href="/"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="fa fa-arrow-up-short"></i>
      </a>
    </>
  );
}
