import React, { useCallback, useEffect, useState } from "react";
import Table from "../components/Table";
import axios from "axios";
import { apiKey } from "../config/config";

export default function ItemReport({lang}) {
  const [inventoryList, setInventoryList] = useState([]);
  const [branchId, setBranchId] = useState();

  const fetchInventory = useCallback(() => {
    try {
      axios.get(apiKey + "/get-items", {
        params: {
          branch_id: branchId
        }
      }).then((res) => {
        setInventoryList(res.data.result);
      });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);

  const columns = [
    {
      header: lang.Item || "Item",
      accessorKey: "inventory_item",
    },
    {
      header: lang.Owner || "Owner",
      accessorKey: "investor_name",
    },
    {
      header: lang.Quantity || "Quantity",
      accessorKey: "inventory_quantity",
    },
  ];

  useEffect(() => {
    if(branchId){
      fetchInventory()
    }
  }, [branchId, fetchInventory]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, [])
  return (

    <>
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>{lang.Reports || "Reports"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"> <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg></li>
              <li className="breadcrumb-item">{lang.Reports || "Reports"}</li>
              <li className="breadcrumb-item active">{lang.Item || "Item"}</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">

              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <div className="rr">{lang.Item_List || "Item List"}</div>
                    {/* <button className="btn btn-export">Export <img src="/img/export.png" alt="export" /> </button> */}

                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang={lang} data={inventoryList} columns={columns} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>


    </>
  )
}
