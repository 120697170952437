import axios from "axios";
import { apiKey } from "../config/config";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function UserProfile({ lang }) {
  const [userDetail, setUserDetail] = useState();
  const { user_type, id } = useParams();

  function convertDateFormat(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    const locale = lang.Lang === "en" ? "en-US" : "ar-SA";

    const formattedDate = dateObject.toLocaleDateString(locale, options);
    return formattedDate;
  }

  useEffect(() => {
    const api =
      user_type === "guarantor"
        ? `${apiKey}/get-guarantor/${id}`
        : `${apiKey}/get-user/${user_type}/${id}`;
    try {
      axios
        .get(api)
        .then((res) => {
          setUserDetail(res.data.result[0]);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [id, user_type]);
  return (
    <main id="main" class="main">
      <div class="pagetitle">
        <h1>
          {lang.Investment_Loan_Management || "Investment/Loan Management"}
        </h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <img src="/img/homeicon.png" alt="logo" class="sidebaricon" />
            </li>
            <li class="breadcrumb-item">
              {lang.Customer_Management || "Customer Management"}
            </li>
            <li class="breadcrumb-item active">
              {lang.Customer || "Customer"}
            </li>
          </ol>
        </nav>
      </div>

      <section class="section">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex mt-3 mb-3">
                  <h4 class="headingh4 fw-bold">
                    {lang.Profile_Information || "Profile Information"}
                  </h4>
                </div>
                {userDetail && (
                  <div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Full_Name || "Full Name"}:
                          </span>{" "}
                          {userDetail.FullName}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Identity_Number || "Identity Number"}:
                          </span>{" "}
                          {userDetail.ValidNationalID}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Identity_Number || "Identity Number"}:
                          </span>{" "}
                          {userDetail.ValidNationalID}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Release_Date || "Release Date"}:
                          </span>{" "}
                          {convertDateFormat(userDetail.ReleaseDate)}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Source || "Source"}:
                          </span>{" "}
                          {userDetail.Source}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Date_of_Birth || "Date Of Birth"}:
                          </span>{" "}
                          {convertDateFormat(userDetail.DoB)}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Gender || "Gender"}:{" "}
                          </span>{" "}
                          {userDetail.Gender}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Mobile_Number || "Mobile Number"}:{" "}
                          </span>{" "}
                          {userDetail.MobilePhoneNo}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Email || "Email"}:
                          </span>{" "}
                          {userDetail.Email}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Nationality || "Nationality"}:
                          </span>{" "}
                          {userDetail.Nationality}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Country || "Country"}:
                          </span>{" "}
                          {userDetail.Country}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">{lang.City || ""}:</span>{" "}
                          {userDetail.City}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 ">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Full_Address || "Full Address"}:
                          </span>{" "}
                          {userDetail.Address}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Occupation || "Occupation"}:
                          </span>{" "}
                          {userDetail.Occupation}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Employer_Name || "Employer Name"}:
                          </span>{" "}
                          {userDetail.EmployerName}
                        </div>
                        <div className="col-sm-4">
                          <span className="fw-bold">
                            {lang.Designation || "Designation"}:
                          </span>{" "}
                          {userDetail.Designation}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div className="row mb-3 col-d-12">
                        <span className="fw-bold">
                          {lang.Description || "Description"}:
                        </span>{" "}
                        <span> {userDetail.AdditionalNotes}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default UserProfile;
