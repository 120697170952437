import axios from "axios";
import { apiKey } from "../config/config";
import React from "react";
import { useEffect, useState } from "react";

function ProfitCalculator({ lang }) {
  const [costPrice, setCostPrice] = useState();
  const [profitRatio, setProfitRatio] = useState();
  const [profit, setProfit] = useState();
  const [contractValue, setContractValue] = useState();
  const [downPayment, setDownPayment] = useState();
  const [finalInstallment, setFinalInstallment] = useState();
  const [remaining, setRemaining] = useState();
  const [noInstallment, setNoInstallment] = useState();

  useEffect(() => {
    if (costPrice) {
      const calculatedContractValue =
        parseInt(costPrice) + (profitRatio * parseInt(costPrice)) / 100;
      setContractValue(calculatedContractValue);
      setProfit((profitRatio * parseInt(costPrice)) / 100);
    }
  }, [costPrice, profitRatio]);

  // Effect to calculate Remaining Amount
  useEffect(() => {
    if (downPayment && contractValue) {
      setRemaining(contractValue - downPayment);
    } else {
      setRemaining(null);
    }
  }, [downPayment, contractValue]);

  // Effect to calculate Final Installment
  useEffect(() => {
    if (remaining !== null && noInstallment) {
      const calculatedFinalInstallment = parseFloat(remaining / noInstallment);
      setFinalInstallment(calculatedFinalInstallment);
    } else {
      setFinalInstallment(null);
    }
  }, [remaining, noInstallment]);

  // Effect to calculate No of Installments
  useEffect(() => {
    if (remaining !== null && finalInstallment) {
      const calculatedNoInstallment = remaining / finalInstallment;
      setNoInstallment(calculatedNoInstallment);
    } else {
      setNoInstallment(null);
    }
  }, [remaining, finalInstallment]);

  useEffect(() => {
    try {
      axios
        .get(`${apiKey}/get-contract-profit`)
        .then((res) => {
          setProfitRatio(res.data.result[0].profit_percentage);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);
  return (
    <div className="modal fade" id="goodsverticalycentered" tabindex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <h2>{lang.Profit_Calculator || "Profit Calculator"}</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault(); /* handleSubmit(); */
              }}
            >
              <div className="row mb-3 d-flex justify-content-center">
                <div className="col-sm-6">
                  <label htmlFor="costPrice" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Cost_Price || "Cost Price"}:</span>
                    <input
                      id="costPrice"
                      type="text"
                      className="form-control"
                      value={costPrice}
                      onChange={(e) => setCostPrice(e.target.value)}
                    />
                  </label>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="profitRatio" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Profit_Ratio || "Profit Ratio"}:</span>
                    <input
                      id="profitRatio"
                      type="text"
                      className="form-control"
                      value={profitRatio}
                      onChange={(e) => setProfitRatio(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              <div className="row mb-3 d-flex justify-content-center">
                <div className="col-sm-6">
                  <label htmlFor="profit" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Profit || 'Profit'}:</span>
                    <input
                      id="profit"
                      type="text"
                      className="form-control"
                      value={profit}
                      onChange={(e) => setProfit(e.target.value)}
                    />
                  </label>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="downPayment" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Down_Payment || "Down Payment"}:</span>
                    <input
                      id="downPayment"
                      type="text"
                      className="form-control"
                      value={downPayment}
                      onChange={(e) => setDownPayment(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              <div className="row mb-3 d-flex justify-content-center">
                <div className="col-sm-6">
                  <label htmlFor="contractValue" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Contract_Value || "Contract Value"}:</span>
                    <input
                      id="contractValue"
                      type="text"
                      className="form-control"
                      value={contractValue}
                      onChange={(e) => setContractValue(e.target.value)}
                    />
                  </label>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="remaining" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Remaining || "Remaining Amount"}:</span>
                    <input
                      id="remaining"
                      type="text"
                      className="form-control"
                      value={remaining}
                      disabled
                    />
                  </label>
                </div>
              </div>

              <div className="row mb-3 d-flex justify-content-center">
                <div className="col-sm-6">
                  <label htmlFor="noInstallment" className="w-100">
                    <span style={{ fontSize: "14px" }}>
                      {lang.No_of_Installments || "No. of Installments"}:
                    </span>
                    <input
                      id="noInstallment"
                      type="text"
                      className="form-control"
                      value={noInstallment}
                      onChange={(e) => setNoInstallment(e.target.value)}
                    />
                  </label>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="finalInstallment" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Final_Installment || "Final Installment"}:</span>
                    <input
                      id="finalInstallment"
                      type="text"
                      className="form-control"
                      value={finalInstallment}
                      onChange={(e) => setFinalInstallment(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              <div className="row mb-3 d-flex justify-content-center">
                <div className="col-sm-6">
                  <label htmlFor="premium" className="w-100">
                    <span style={{ fontSize: "14px" }}>{lang.Amount_of_Premium || "Amount of Premium"}:</span>
                    <input
                      id="premium"
                      type="text"
                      className="form-control"
                      value={finalInstallment}
                    />
                  </label>
                </div>
              </div>

              <div className="text-center">
                <button
                  type="default"
                  className="btn-save"
                  data-bs-dismiss="modal"
                >
                {lang.Cancel || "Cancel"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfitCalculator;
