import axios from "axios";
import { apiKey } from "../config/config";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";

export default function AddNewDealer({lang}) {
  const [fullName, setFullName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [validID, setValidID] = useState();
  const [source, setSource] = useState();
  const [releaseDate, setReleaseDate] = useState(new Date());
  const [branchName, setBranchName] = useState();
  const [branchLocation, setBranchLocation] = useState();
  const [dealerType, setDealerType] = useState();
  const [password, setPassword] = useState();
  const [additional, setAdditional] = useState();
  const [selectedCalendar, setSelectedCalendar] = useState("arabic");

  const navigate = useNavigate();

  const handleCalendarChange = (calendarType) => {
    setSelectedCalendar(calendarType);
  };

  const handleSubmit = () => {
    axios
      .post(apiKey + "/add-dealer", {
        customer_name: fullName,
        contact_number: phoneNo,
        email: email,
        address: address,
        city: city,
        valid_id_number: validID,
        source: source,
        release_date: releaseDate,
        branch_name: branchName,
        branch_location: branchLocation,
        dealer_type: dealerType,
        branch_logo: "",
        role: "dealer",
        password: password,
        additional_info: additional,
      })
      .then((res) => {
        console.log(res.data.Message);
        navigate("/dashboard/dealer-list");
      })
      .catch((err) => {
        console.error(err.response.data.Error);
        toast.error(err.response.data.Error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleReleaseDateChange = (value) => {
    const dateObject = new Date(value);
    setReleaseDate(dateObject)
  }

  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="container-fluid">
          <div className="pagetitle">
            <h1>{lang.Dealer_Management || "Dealer Management"}</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                  >
                    <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                  </svg>
                </li>
                <li className="breadcrumb-item">{lang.Dealer_Management || "Dealer Management"}</li>
                <li className="breadcrumb-item">{lang.dealer_list || "Dealer List"}</li>
                <li className="breadcrumb-item active">{lang.add_new_dealer || "Add New Dealer"}</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container-fluid">
          <section className="section">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex mt-3 mb-3">
                      <h4 className="headingh4 fw-bold">{lang.add_new_dealer || "Add New Dealer"}</h4>
                    </div>

                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="d-flex mt-3 mb-3 justify-content-end">
                      <label className="select-calendar">
                          <span style={{ fontSize: "14px" }}>
                            {lang.select_calendar || "Select Calendar"}:
                          </span>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="arabicRadio"
                              value="arabic"
                              checked={selectedCalendar === "arabic"}
                              onChange={() => handleCalendarChange("arabic")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="arabicRadio"
                            >
                              {lang.arabic || "Arabic"}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="gregorianRadio"
                              value="gregorian"
                              checked={selectedCalendar === "gregorian"}
                              onChange={() => handleCalendarChange("gregorian")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gregorianRadio"
                            >
                              {lang.gregorian || "Gregorian"}
                            </label>
                          </div>
                        </label>
                        <button
                          type="button"
                          onClick={() => navigate("/dashboard/dealer-list")}
                          className="btn-save"
                        >
                          {lang.Cancel || "Cancel"}
                        </button>
                        <button type="submit" className="btn-cancel">
                        {lang.Save_And_Publish || "Save & Publish"}
                        </button>
                      </div>

                      <div className="d-flex mt-3 mb-3">
                        <h4 class="headingh4 fw-bold">{lang.Basic_Information || "Basic Information"}</h4>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>{lang.Full_Name || "Full Name"}</span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>
                              {lang.Phone_Number || "Phone Number"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={phoneNo}
                              onChange={(e) => setPhoneNo(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>{lang.Email || "Email"}</span>
                            <input
                              type="email"
                              className="form-control"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>{lang.City || "City"}</span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>{lang.Address || "Address"}</span>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="2"
                              required
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            ></textarea>
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>
                              {lang.Valid_Identity_Number || "Valid National ID"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={validID}
                              onChange={(e) => setValidID(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>{lang.Source || "Place of issue"}</span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={source}
                              onChange={(e) => setSource(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100 d-flex" style={{flexDirection: "column"}}>
                            <span style={{ fontSize: "14px" }}>
                              {lang.Release_Date || "Release Date"}
                            </span>
                            <DatePicker
                              className="form-control w-100"
                              containerStyle={{
                                width: "100%",
                                height: "100%"
                              }}
                              style={{
                                width: "100%",
                                height: "100%",
                                margin: "0",
                              }}
                              calendar={
                                selectedCalendar === "arabic" ? arabic : ""
                              }
                              locale={
                                selectedCalendar === "arabic" ? arabic_ar : ""
                              }
                              value={releaseDate}
                              onChange={handleReleaseDateChange}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="d-flex mt-3 mb-3">
                        <h4 class="headingh4 fw-bold">{lang.office_info || "Office Information"}</h4>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>
                              {lang.branch_name || "Office Name"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={branchName}
                              onChange={(e) => setBranchName(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>
                              {lang.branch_location || "Office Location"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={branchLocation}
                              onChange={(e) =>
                                setBranchLocation(e.target.value)
                              }
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>
                              {lang.dealer_type || "Dealer Type"}
                            </span>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              required
                              value={dealerType}
                              onChange={(e) => setDealerType(e.target.value)}
                            >
                              <option selected disabled></option>
                              <option value="Independent">{lang.independent || "Independent"}</option>
                              <option value="Office">{lang.office || "Office"}</option>
                            </select>
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>{lang.Password || "Password"}</span>
                            <input
                              type="password"
                              className="form-control"
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <label htmlFor="" className="w-100">
                            <span style={{ fontSize: "14px" }}>
                              {lang.Additional_Notes || "Additional Information"}
                            </span>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea"
                              rows="5"
                              value={additional}
                              onChange={(e) => setAdditional(e.target.value)}
                            ></textarea>
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
