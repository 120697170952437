/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { adminLogin } from "../state/actions/authActions";
import axios from "axios";
import { apiKey } from "../config/config";

function LoginPage() {
  // const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  // const handleLogin = () => {
  //   dispatch(adminLogin(email, password));
  // };

  const handleLogin = () => {
    try {
      axios
        .post(apiKey + "/login-dealer", {
          email,
          password,
        })
        .then((res) => {
          if (res.data.dealer.status == 1){
            sessionStorage.setItem(
              "aman-admin",
              JSON.stringify({
                // token: res.data.accessToken,
                admin: JSON.stringify(res.data.dealer),
              })
            );
            navigate("/dashboard");
          }
          else {
            setError("This account has been deactivated.")
          }
        })
        .catch((err) => {
          console.error(err);
          setError("Invalid Credentials Or Some other error");
        });
    } catch (err) {
      console.error(err);
      setError("Some Error Occured");
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("aman-admin")) {
      navigate("/dashboard");
    }
  });
  return (
    <div class="container-fluid w-100">
      <div class="row login-main">
        <div class="left-container d-none d-md-block col-lg-6 col-md-12 d-flex justify-content-center align-items-center py-5 h-100 bg-[#F0F3F7]">
          <div class="text-center">
            <img src="/img/background.png" alt="" class="img-fluid" />
            <h1 class="loginh1">أمان القروض</h1>
            <p class="loginp">
              البرنامج الوحيد بالمملكة الذي يوفر لك الأمان ويساعدك في كشف
              العملاء المتلاعبين ومن لديهم تعثر في السداد، ويدير مكتبك بطريقة
              إلكترونية في أي وقت ومن أي مكان.
            </p>
            <img src="/img/loading.png" alt="" class="img-fluid loaderlogin" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 py-5">
          <div class="right-container d-flex flex-column justify-content-center align-items-center h-100">
            <div className="text-center">
              <img src="/img/logo.png" alt="logo" />
              <h1 class="">Welcome Back</h1>
              <p class="">Please login to access your account</p>
            </div>

            <form
              class="col-8"
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <div class="">
                <label>E-mail</label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div class="">
                <label>Password</label>
                <input
                  type="password"
                  name=""
                  class="form-control"
                  placeholder="Type your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p class="">Forgot Passwords</p>
              </div>

              <div class="">
                <button class="btn btn-primary w-100"> Submit </button>
                {/* {auth.error && <p>{auth.error}</p>} */}
                <p class="account mt-2">Don't have an account? Register</p>
              </div>
              {error && (
                <p className="bg-danger text-white text-center">{error}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
