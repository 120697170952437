/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { apiKey } from "../config/config";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

export default function Identification({ lang }) {
  const [itemsList, setItemsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalPurchase, setTotalPurchase] = useState();
  const [totalQuantity, setTotalQuantity] = useState();
  const [available, setAvailable] = useState();
  const [quantity, setQuantity] = useState();
  const [capital, setCapital] = useState();
  const [unitPrice, setUnitPrice] = useState();
  const [totalSellPrice, setTotalSellPrice] = useState();
  const [profit, setProfit] = useState();
  const [legalFees, setLegalFees] = useState();
  const [investor, setInvestor] = useState();
  const [description, setDescription] = useState();
  const [branchId, setBranchId] = useState();
  const [item, setItem] = useState();

  const [investors, setInvestors] = useState([]);

  const pathName = useLocation();

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (available === "Unavailable") {
      alert("Quantity is unavailable")
      return;
    }
    const formData2 = {
      investor,
      item,
      quantity,
      description,
      unitPrice,
      totalSellPrice,
      profit,
      legalFees,
    };
    sessionStorage.setItem("formData2", JSON.stringify(formData2));
    if (pathName.pathname.includes("future")) {
      navigate("/dashboard/contracts/future/contract-information");
    } else {
      navigate("/dashboard/contracts/contract-information");
    }
  };

  useEffect(() => {
    if (branchId) {
      try {
        axios
          .get(apiKey + "/get-system-inventory/" + branchId)
          .then((res) => {
            const mappedOptions = res.data.result.map((item) => ({
              value: item.id,
              label: item.inventory_name,
              investor: item.investor_id,
              price: item.inventory_price,
              quantity: item.inventory_quantity,
              capital: item.capital,
              inventory_total_price: item.inventory_total_price,
            }));
            setItemsList(mappedOptions);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [branchId]);

  useEffect(() => {
    if (totalQuantity < quantity) {
      setAvailable(lang.unavailable || "Unavailable");
      return;
    }
    setAvailable(lang.Available || "Available");
  }, [quantity]);

  useEffect(() => {
    setUnitPrice((totalSellPrice / quantity).toFixed(2));
  }, [totalSellPrice]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);

  useEffect(() => {
    if (selectedItem) {
      axios
        .get(apiKey + "/get-inv-for-contract", {
          params: {
            branchId,
            selectedItem,
          },
        })
        .then((res) => {
          let i = 0;
          const mappedOptions = res.data.result.map((item) => {
            i += 1;
            return {
              value: i + 1,
              item_id: item.id,
              investor: item.investor_id,
              label: item.investor_name,
              price: item.inventory_price,
              quantity: item.inventory_quantity,
              capital: item.capital,
              inventory_total_price: item.inventory_total_price,
            };
          });
          setInvestors(mappedOptions);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedItem]);

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item">
                {lang.Contracts || "Contracts"}
              </li>
              <li className="breadcrumb-item">
                {lang.New_Installment_Contracts || "New Installments Contracts"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Identification_of_Goods || "Identification of Goods"}
              </li>
            </ol>
          </nav>
        </div>

        <div className="card-body pt-3 ">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              {/* <Link to="/basic-information"> */}
              <button className="nav-link ">
                {lang.Basic_Information || "Basic information"}
              </button>
              {/* </Link> */}
            </li>

            <li className="nav-item">
              {/* <Link to="/identification"> */}
              <button className="nav-link active">
                {lang.Identification_of_Goods || "Identification of Goods"}
              </button>
              {/* </Link> */}
            </li>

            <li className="nav-item">
              {/* <Link to="/contract-information"> */}
              <button className="nav-link">
                {lang.Contract_Information || "Contract Information"}
              </button>
              {/* </Link> */}
            </li>
          </ul>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Identification_of_Goods ||
                        "Identification of Goods"}
                    </h4>
                  </div>

                  <form onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}>
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button className="btn-save">
                        {lang.Cancel || "Cancel"}
                      </button>
                      <button type="submit" className="btn-cancel">
                        {lang.Save_And_Next || "Save & Next"}
                      </button>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="itemName" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Item_Name || "Item Name"}:
                          </span>
                          <Select
                            id="itemName"
                            options={itemsList}
                            required
                            placeholder={""}
                            onChange={(e) => {
                              setSelectedItem(e.value);
                              setTotalQuantity(e.quantity);
                              setItem(e.item_id)
                            }}
                          />
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="investor" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Investor || "Investor"}:
                          </span>
                          <Select
                            options={investors}
                            required
                            placeholder={""}
                            onChange={(e) => {
                              setInvestor(e.investor);
                              setTotalQuantity(e.quantity);
                              setItem(e.item_id)
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="quantity" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Quantity || "Quantity"}:
                          </span>
                          <input
                            id="quantity"
                            type="text"
                            className="form-control"
                            value={quantity}
                            onChange={(e) => {
                              setQuantity(parseInt(e.target.value) || 0);
                              // setTotalPurchase(
                              //   parseInt(e.target.value) * purchasePrice || 0
                              // );
                            }}
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="available" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Available || "Available"}:
                          </span>
                          <input
                            id="available"
                            type="text"
                            className="form-control"
                            value={available}
                            readOnly
                          />
                        </label>
                      </div>

                      {/* <div className="col-lg-4">
                        <label htmlFor="purchasePrice" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Purchase_Price || "Purchase Price"}:
                          </span>
                          <input
                            id="purchasePrice"
                            type="text"
                            className="form-control"
                            value={purchasePrice}
                            readOnly
                          />
                        </label>
                      </div> */}
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="unitPrice" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.total_purchase_price ||
                              "Total Purchase Price"}
                          </span>
                          <input
                            id="unitPrice"
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              setTotalPurchase(parseInt(e.target.value) || 0)
                            }
                            value={totalPurchase}
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="capital" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.total_selling_price || "Total Selling Price"}
                          </span>
                          <input
                            id="capital"
                            type="text"
                            className="form-control"
                            value={totalSellPrice}
                            onChange={(e) => {
                              setTotalSellPrice(e.target.value);
                              setCapital(e.target.value - totalPurchase);
                              setProfit(e.target.value - totalPurchase)
                            }}
                            // disabled
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="unitPrice" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Unit_Selling_Price || "Unit Selling Price"}:
                          </span>
                          <input
                            id="unitPrice"
                            type="text"
                            className="form-control"
                            value={unitPrice || 0}
                            // onChange={(e) =>
                            //   setUnitPrice(parseInt(e.target.value))
                            // }
                            disabled
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="capital" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.total_profit || "Total Profit"}:
                          </span>
                          <input
                            id="capital"
                            type="text"
                            className="form-control"
                            value={capital}
                            disabled
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="profit" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Profit_Ratio || "Profit Ratio"}:
                          </span>
                          <input
                            id="profit"
                            type="email"
                            className="form-control"
                            value={profit || 0}
                            disabled
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="profit" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.legal_fees || "Legal Fees"}:</span>
                          <input
                            type="text"
                            className="form-control"
                            value={legalFees}
                            onChange={(e) =>
                              setLegalFees(parseInt(e.target.value) || 0)
                            }
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100"></label>
                        <button
                          type="button"
                          className="form-control d-flex align-items-center justify-content-between bg-light"
                          onClick={() => {
                            navigate("/dashboard/inventory/inventory-purchase");
                          }}
                        >
                          {lang.Purchase_Item || "Purchase Item"}
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.625 9C17.625 9.09946 17.5855 9.19484 17.5152 9.26517C17.4448 9.33549 17.3495 9.375 17.25 9.375H9.375V17.25C9.375 17.3495 9.33549 17.4448 9.26517 17.5152C9.19484 17.5855 9.09946 17.625 9 17.625C8.90054 17.625 8.80516 17.5855 8.73483 17.5152C8.66451 17.4448 8.625 17.3495 8.625 17.25V9.375H0.75C0.650544 9.375 0.555161 9.33549 0.484835 9.26517C0.414509 9.19484 0.375 9.09946 0.375 9C0.375 8.90054 0.414509 8.80516 0.484835 8.73483C0.555161 8.66451 0.650544 8.625 0.75 8.625H8.625V0.75C8.625 0.650544 8.66451 0.555161 8.73483 0.484835C8.80516 0.414509 8.90054 0.375 9 0.375C9.09946 0.375 9.19484 0.414509 9.26517 0.484835C9.33549 0.555161 9.375 0.650544 9.375 0.75V8.625H17.25C17.3495 8.625 17.4448 8.66451 17.5152 8.73483C17.5855 8.80516 17.625 8.90054 17.625 9Z"
                              fill="#5570F1"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="col-lg-4"></div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-8">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Description || ""}:
                          </span>
                          <textarea
                            class="form-control"
                            rows="5"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
