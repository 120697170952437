import axios from "axios";
import { apiKey } from "../config/config";
import React, { useEffect, useState } from "react";

function AdvanceOptions({ lang, dir }) {
  //   console.log(lang);
  const [profit, setProfit] = useState();
  const [accountList, setAccountList] = useState([]);
  const [defaultAccount, setDefaultAccount] = useState();
  const [language, setLanguage] = useState();
  const [branchId, setBranchId] = useState();

  const [editedTranslations, setEditedTranslations] = useState({});

  const handleChange = (word, value) => {
    setEditedTranslations((prevTranslations) => ({
      ...prevTranslations,
      [word]: value,
    }));
  };

  const handleSubmit = (event, word) => {
    event.preventDefault();
    // onUpdateTranslation(word, editedTranslations[word]);
    // console.log(event.target.elements[0].value);
    // console.log(word);
    try {
      axios
        .put(apiKey + "/update-word", {
          key: word,
          word: event.target.elements[0].value,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccountUpdate = (e) => {
    e.preventDefault();
    try {
      axios
        .put(apiKey + "/update-default-account/" + defaultAccount)
        .then((res) => {
          console.log(res.data);
          alert("Success");
        })
        .catch((err) => {
          console.error(err);
          alert("Error");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleProfitUpdate = (e) => {
    e.preventDefault();
    try {
      axios
        .put(apiKey + "/update-contract-profit", { profit: profit })
        .then((res) => {
          console.log(res.data.Message);
          fetchProfit();
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProfit = () => {
    try {
      axios
        .get(apiKey + "/get-contract-profit")
        .then((res) => {
          setProfit(res.data.result[0].profit_percentage);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLang = () => {
    try {
      axios
        .get(apiKey + "/get-lang")
        .then((res) => {
          setLanguage(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      fetchProfit();
      fetchLang();
      if(branchId){
        axios
        .get(apiKey + "/get-accounts", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setAccountList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <main id={`main`} className={`main`}>
      <div className="pagetitle">
        <h1>{lang.General_Management || "General Management"}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
              >
                <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
              </svg>
            </li>
            <li className="breadcrumb-item">
              {lang.General_Management || "General Management"}
            </li>
            <li className="breadcrumb-item active">
              {lang.Advance_Settings || "Advance Options"}
            </li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex mt-3 mb-3">
                  <h4 className="headingh4 fw-bold">
                    {lang.Advance_Settings || "Advance Options"}
                  </h4>
                </div>
                <div>
                  <div>
                    <form
                      onSubmit={handleAccountUpdate}
                      className="row mb-3 d-flex justify-content-center align-items-center"
                    >
                      <label className="col-sm-2" htmlFor="">
                        {lang.Default_Account || "Default Account"}:
                      </label>
                      <div className="col-sm-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setDefaultAccount(e.target.value)}
                        >
                          {accountList.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.account_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-4">
                        <button type="submit" className="btn-save">
                          {lang.Update_Text || "Update"}
                        </button>
                      </div>
                    </form>
                    <form
                      onSubmit={handleProfitUpdate}
                      className="row mb-3 d-flex justify-content-center align-items-center"
                    >
                      <label className="col-sm-2" htmlFor="">
                        {lang.Default_Profit_Percentage || "Default Profit %"}:
                      </label>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={profit}
                          onChange={(e) => setProfit(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-sm-4">
                        <button type="submit" className="btn-save">
                          {lang.Update_Text || "Update"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="d-flex mt-3 mb-3">
                  <h4 className="headingh4 fw-bold">
                    {lang.Language_Settings || "Language Settings"}
                  </h4>
                </div>

                <div>
                  {language &&
                    Object.keys(language[0]).map((word, index) => (
                      <>
                        {word !== "Lang" && (
                          <form
                            className="row mb-3 d-flex justify-content-center align-items-center"
                            key={index}
                            onSubmit={(e) => handleSubmit(e, word)}
                          >
                            <label className="col-sm-2" htmlFor={word}>
                              {language[0][word]}
                            </label>
                            <div className="col-sm-4">
                              <input
                                type="text"
                                id={word}
                                value={
                                  editedTranslations[word] || language[1][word]
                                }
                                className="form-control"
                                onChange={(e) =>
                                  handleChange(word, e.target.value)
                                }
                              />
                            </div>
                            <div className="col-sm-4">
                              <button className="btn-save" type="submit">
                                Submit
                              </button>
                            </div>
                          </form>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AdvanceOptions;
