import axios from "axios";
import { apiKey } from "../config/config";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddLoanList({ lang }) {
  const [loanType, setLoanType] = useState();
  const [branchId, setBranchId] = useState();

  const handleSubmit = () => {
    try {
      axios
        .post(apiKey + "/add-loan-type", {
          loan_type: loanType,
          branch_id: branchId,
        })
        .then((res) => {
          if (res.status === 200) {
            setLoanType("");
            toast.success(res.data.Message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error Occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Error Occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Type_of_Loans || "Type Of Loan"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Add_Loan_Type || "Add Loan Type"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Add_Loan_Type || "Add Loan Type"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button className="btn-save">
                        {lang.Save_as_Draft || "Save as Draft"}{" "}
                      </button>
                      <button type="submit" className="btn-cancel">
                        {lang.Save_And_Publish || "Save & Publish"}
                      </button>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="" className="w-100"></label>
                        <span style={{ fontSize: "14px" }}>
                          {lang.Name_Type_of_Loan || "Name Type of Loan"}:
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={loanType}
                          onChange={(e) => setLoanType(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
