import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiKey } from "../config/config";
import Select from "react-select";

export default function BasicInfo({ lang }) {
  const [customerList, setCustomerList] = useState([]);
  const [guarantorList, setGuarantorList] = useState([]);
  const [customer, setCustomer] = useState();
  const [guarantor1, setGuarantor1] = useState();
  const [guarantor2, setGuarantor2] = useState();
  const [branchId, setBranchId] = useState();

  const pathName = useLocation();

  const navigate = useNavigate();

  const handleSubmit = () => {
    // Check if required information is filled
    if (!customer) {
      alert(
        "Please fill in all required information (Customer) before proceeding."
      );
      return; // Do not proceed to the next page
    }

    // Gather form data
    const formData = {
      customer,
      guarantor1,
      guarantor2,
    };

    // Store form data in sessionStorage
    sessionStorage.setItem("formData", JSON.stringify(formData));

    // Navigate to the next page
    if (pathName.pathname.includes("future")) {
      navigate("/dashboard/contracts/future/identification");
    } else {
      navigate("/dashboard/contracts/identification");
    }
  };

  useEffect(() => {
    if (branchId) {
      try {
        axios
          .get(apiKey + "/get-user/customer", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            const mappedOptions = res.data.result.map((item) => ({
              value: item.id,
              label: item.FullName,
            }));
            setCustomerList(mappedOptions);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  useEffect(() => {
    if (customer){
      try {
        axios
          .get(apiKey + "/get-guarantor?customer_id=" + customer)
          .then((res) => {
            setGuarantorList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [customer]);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item">
                {lang.Contracts || "Contracts"}
              </li>
              <li className="breadcrumb-item">
                {lang.New_Installment_Contracts || "New Installments Contracts"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Basic_Information || "Basic information"}
              </li>
            </ol>
          </nav>
        </div>

        <div className="card-body pt-3 ">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <button className="nav-link active">
                {lang.Basic_Information || "Basic information"}
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link">
                {lang.Identification_of_Goods || "Identification of Goods"}
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link">
                {lang.Contract_Information || "Contract Information"}
              </button>
            </li>
          </ul>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Basic_Information || "Basic Information"}
                    </h4>
                  </div>

                  <div className="d-flex mt-3 mb-3 justify-content-end">
                    <button type="submit" className="btn-save">
                      {lang.Cancel || "Cancel"}
                    </button>
                    <button
                      type="submit"
                      className="btn-cancel ms-4"
                      onClick={handleSubmit}
                    >
                      {lang.Save_And_Next || "Save & Next"}
                    </button>
                  </div>

                  <form>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="customer" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Client || "Client"}:
                          </span>
                          {/* <select
                            id="customer"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setCustomer(e.target.value)}
                          >
                            <option defaultValue="">Client</option>
                            {customerList.map((customer) => (
                              <>
                                {customer.status === 1 && (
                                  <option key={customer.id} value={customer.id}>
                                    {customer.FullName}
                                  </option>
                                )}
                              </>
                            ))}
                          </select> */}
                          <Select
                            id="itemName"
                            options={customerList}
                            placeholder={""}
                            required
                            onChange={(e) => {
                              setCustomer(e.value);
                              console.log(e);
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="guarantor1" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Select_Guarantor_1 || "Select Guarantor"}:
                          </span>
                          <select
                            id="guarantor1"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setGuarantor1(e.target.value)}
                          >
                            <option defaultValue="">Select Guarantor</option>
                            {guarantorList.map((guarantor) => (
                              <option key={guarantor.id} value={guarantor.id}>
                                {guarantor.FullName}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="guarantor2" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Select_Guarantor_2 || "Select Guarantor"}:
                          </span>
                          <select
                            id="guarantor2"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setGuarantor2(e.target.value)}
                          >
                            <option defaultValue="">Select Guarantor</option>
                            {guarantorList.map((guarantor) => (
                              <option key={guarantor.id} value={guarantor.id}>
                                {guarantor.FullName}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
