import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { apiKey } from "../config/config";
import Table from "../components/Table";

function ShiftReport({ lang }) {
  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState([]);
  const [branchId, setBranchId] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  function convertDateFormat(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const locale = lang.Lang === "en" ? "en-US" : "ar-SA";

    const formattedDate = dateObject.toLocaleDateString(locale, options);
    return formattedDate;
  }

  const columns = [
    {
      header: lang.Opening_Balance || "Opening Balance",
      accessorKey: "opening_balance",
    },
    {
      header: lang.closing_balance || "Closing Balance",
      accessorKey: "closing_balance",
    },
    {
      header: lang.total_contracts || "Total Contracts",
      accessorKey: "total_contracts",
    },
    {
      header: lang.total_contracts_amount || "Total Contracts Amount",
      accessorKey: "total_contracts_amount",
    },
    {
      header: lang.total_contract_payments || "Total Contract Payments",
      accessorKey: "total_contract_payments",
    },
    {
      header: lang.total_contract_payments_amount || "Total Contract Payments Amount",
      accessorKey: "total_contract_payments_amount",
    },
    {
      header: lang.total_withdrawals || "Total Withdrawals",
      accessorKey: "total_withdrawals",
    },
    {
      header: lang.total_withdrawals_amount || "Total Withdrawals Amount",
      accessorKey: "total_withdrawals_amount",
    },
    {
      header: lang.total_deposits || "Total Deposits",
      accessorKey: "total_deposits",
    },
    {
      header: lang.total_deposits_amount || "Total Deposits Amount",
      accessorKey: "total_deposits_amount",
    },
    {
      header: lang.start_time || "Start Time",
      accessorKey: "start_time",
      cell: (row) => convertDateFormat(row.getValue()),
    },
    {
      header: lang.end_time || "End Time",
      accessorKey: "end_time",
      cell: (row) => convertDateFormat(row.getValue()),
    },
  ];

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  useEffect(() => {
    if (filter !== "from_to") {
      setFrom();
      setTo();
    }
    if(branchId){
      axios.get(`${apiKey}/shift-report/${branchId}`, {
        params: {
          filter,
          from,
          to,
        },
      }).then(res => {
        setReports(res.data.data)
      }).catch(err => {
        console.log(err)
      });
    }
  }, [branchId, filter, from, to]);

  return (
    <main className="main" id="main">
      <div className="pagetitle">
        <h1>{lang.Reports || "Reports"}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
              >
                <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
              </svg>
            </li>
            <li className="breadcrumb-item">{lang.Reports || "Reports"}</li>
            <li className="breadcrumb-item active">
              {lang.shift_report || "Shift Reports"}
            </li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="mt-3 mb-3">
                  <div className="rr">
                    {lang.shift_report || "Shift Reports"}
                  </div>
                  <div className="row row justify-content-end">
                    {filter === "from_to" && (
                      <>
                        <DatePicker
                          className="form-control w-100"
                          placeholder="From"
                          style={{
                            left: "35%",
                            top: "5px",
                            height: "auto",
                          }}
                          containerStyle={{
                            height: "auto",
                          }}
                          // calendar={
                          //   selectedCalendar === "arabic" ? arabic : ""
                          // }
                          // locale={
                          //   selectedCalendar === "arabic" ? arabic_ar : ""
                          // }
                          // value={currentDate}
                          onChange={(e) => {
                            const dateObject = new Date(e);
                            setFrom(dateObject);
                          }}
                        />
                        <DatePicker
                          className="form-control w-100"
                          placeholder="To"
                          style={{
                            left: "45%",
                            top: "5px",
                            height: "auto",
                          }}
                          containerStyle={{
                            height: "auto",
                          }}
                          // calendar={
                          //   selectedCalendar === "arabic" ? arabic : ""
                          // }
                          // locale={
                          //   selectedCalendar === "arabic" ? arabic_ar : ""
                          // }
                          // value={currentDate}
                          onChange={(e) => {
                            const dateObject = new Date(e);
                            setTo(dateObject);
                          }}
                        />
                      </>
                    )}
                    <select
                      onChange={(e) => setFilter(e.target.value)}
                      name=""
                      id=""
                      className="form-data btn-export me-0 mb-4 mb-md-0 me-md-4"
                    >
                      <option value="">{lang.All_Time || "All Time"}</option>
                      <option value="today">{lang.Today || "Today"}</option>
                      <option value="this_week">
                        {lang.This_Week || "This Week"}
                      </option>
                      <option value="this_month">
                        {lang.This_Month || "This Month"}
                      </option>
                      <option value="this_year">
                        {lang.This_Year || "This Year"}
                      </option>
                      <option value="from_to">From To</option>
                    </select>
                  </div>
                </div>
                <div classNames="d-flex mt-3 mb-3">
                  <Table lang={lang} data={reports} columns={columns} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ShiftReport;
