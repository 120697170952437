import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Report({ lang }) {
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    const storedUser = JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin)
    if (storedUser) {
      setUserRole(storedUser.role)
    }
  }, [])
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Reports || "Reports"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item active">{lang.Reports || "Reports"}</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <h4 className="rr mt-5">{lang.Reports || "Reports"}</h4>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="investor-report-list" className="report-ref">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.Investors || "Investors"}</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="user-report">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.User || "User"}</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="investor-user-report">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.investor_customer_relation || "Investor/Customer Relation"}</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="transaction-report">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.transaction_report || "Transaction Report"}</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="shift-report">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.shift_report || "Shift Report"}</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="pool-report">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.Investment_Pool || "Investment Pool"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="loan-report-list">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.Loan || "Loan"}</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="item-report-list">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">{lang.Items || "Items"}</span>
                      </Link>
                    </div>
                  </div>
                </div>

               {userRole === "super" && <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="dealer-report-list">
                        <img src="/img/User.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.Dealers || "Dealers"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>}

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="purchase-report">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.purchase_reports || "Purchase Reports"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="list-of-contract">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.Collections_and_Payments ||
                            "Collection & Payment"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="defaulted-contracts">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.Defaulted_Contracts || "Defaulted Contracts"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="contract-profit">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.complete_contract || "Complete Contract"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="pure-contracts">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.Pure_Contracts || "Pure Contracts"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="installment-payments">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                        {lang.Future + " " + lang.Contracts ||
                  "Future Contracts"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
