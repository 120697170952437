import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import axios from "axios";
import { apiKey } from "../config/config";

export default function DealerReport({ lang }) {
  const [dealers, setDealers] = useState([]);

  const columns = [
    {
      header: lang.dealer_name || "Dealer Name",
      accessorKey: "customer_name",
    },
    {
      header: lang.Email || "Email",
      accessorKey: "email",
    },
    {
      header: lang.Valid_Identity_Number || "Valid Identity Number",
      accessorKey: "valid_id_number",
    },
    {
      header: lang.branch_name || "Office Name",
      accessorKey: "branch_name",
    },
    {
      header: lang.dealer_type || "Dealer Type",
      accessorKey: "dealer_type",
    },
  ];
  useEffect(() => {
    try{
      axios.get(apiKey + "/get-dealers").then(res => {
        setDealers(res.data.result)
      }).catch(err => {
        console.error(err)
      })
    } catch(err) {
      console.error(err)
    }
  }, [])
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Reports</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {" "}
                <img
                  src="/img/homeicon.png"
                  alt="logo"
                  className="sidebaricon"
                />
              </li>
              <li className="breadcrumb-item">Reports</li>
              <li className="breadcrumb-item active">Dealer</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <div className="rr">Dealer List</div>
                    {/* <button className="btn btn-export">
                      Export <img src="/img/export.png" alt="export" />{" "}
                    </button> */}
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang={lang} data={dealers} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
