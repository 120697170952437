import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

function Receipt({ lang, receiptData, branchData, idx }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function convertDateFormat(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    const locale = lang.Lang === "en" ? "en-US" : "ar-SA";

    const formattedDate = dateObject.toLocaleDateString(locale, options);
    return formattedDate;
  }
  return (
    <>
      <button
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target={`#myModal${idx}`}
      >
        {lang.receipt}
      </button>
      <div className="modal fade in" id={`myModal${idx}`} tabindex="-1">
        <div className="modal-content modal-dialog">
          <div className="modal-body print position-relative">
            <button
              type="button"
              className="close"
              data-bs-toggle="modal"
              data-bs-target="#myModal1"
            >
              <i className="fa fa-2x">×</i>
            </button>
            <button
              type="button"
              // onClick={() => toPDF()}
              onClick={handlePrint}
              className="btn btn-primary position-absolute top-0 end-0"
              style={{ margin: "1rem 1rem" }}
            >
              {lang.print_reciept || "Print Receipt"}
            </button>
            <div
              id="receipt-pdf"
              className="p-4"
              dir={lang.Lang === "ar" ? "rtl" : "ltr"}
              // ref={targetRef}
              ref={componentRef}
            >
              <div className="row padding10">
                <div className="col-xs-5">
                  {lang.to_text || "To"}:
                  <br />
                  <h2 className="">
                    {receiptData?.type === "withdraw"
                      ? receiptData?.FullName
                      : branchData?.customer_name}
                  </h2>
                  {/* Supplier Address */}
                  {/* <br />
                  {receiptData?.branch_location}
                  <br /> */}
                  {lang.tel || "Tel"}:{" "}
                  {receiptData?.type === "withdraw"
                    ? receiptData?.MobilePhoneNo
                    : branchData?.contact_number}
                  <br />
                  {lang.Email || "Email"}:{" "}
                  {receiptData?.type === "withdraw"
                    ? receiptData?.Email
                    : branchData?.email}
                </div>
                <div className="col-xs-5">
                  <br />
                  {lang.from_text || "From"}:
                  <br />
                  <h2 className="">
                    {receiptData?.type === "withdraw"
                      ? branchData?.customer_name
                      : receiptData?.FullName}
                  </h2>
                  <p>{receiptData?.Address}</p>
                  <p></p>
                  {lang.tel || "Tel"}:{" "}
                  {receiptData?.type === "withdraw"
                    ? branchData?.contact_number
                    : receiptData?.MobilePhoneNo}
                  <br />
                  {lang.Email || "Email"}:{" "}
                  {receiptData?.type === "withdraw"
                    ? branchData?.email
                    : receiptData?.Email}{" "}
                  <div className="clearfix"></div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  <p style={{ fontWeight: "bold" }}>
                    {lang.date || "Date"}:{" "}
                    {convertDateFormat(receiptData?.date)}
                  </p>
                  {/* <p style={{ fontWeight: "bold" }}>
                    {lang.reference_no || "Reference No"}:{" "}
                    {receiptData?.reference_number}
                  </p> */}
                </div>
                <p>&nbsp;</p>

                <div style={{ clear: "both" }}></div>
              </div>
              <div className="well">
                <table
                  className="table table-borderless"
                  style={{ marginBottom: "0" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <strong>{lang.payment_sent || "Payment Sent"}</strong>
                      </td>
                      <td className="text-right">
                        <strong className="text-right">
                          {receiptData?.amount}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>
                        <strong>{lang.paid_by || "Paid by"}</strong>
                      </td> */}
                      <td className="text-right">
                        <strong className="text-right">
                          {receiptData?.payment_method}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ clear: "both" }}></div>
              <div className="row">
                <div className="col-sm-4 pull-left">
                  <p>&nbsp;</p>

                  <p>&nbsp;</p>

                  <p>&nbsp;</p>

                  <p style={{ borderBottom: "1px solid #666" }}>&nbsp;</p>

                  <p>{lang.stamp_and_sign || "Stamp & Signature"}</p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Receipt;
