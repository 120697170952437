import React, { useEffect, useState } from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiKey } from "../config/config";

export default function CusManGuarantor({ lang }) {
  const [guarantorList, setGuarantorList] = useState([]);
  const [guarantorToDelete, setGuarantorToDelete] = useState();
  const [branchId, setBranchId] = useState();

  const navigate = useNavigate();

  const returnTable = async (table) => {
    await setGuarantorToDelete(table.map((item) => item.original.id));
  };

  const fetchGuarantor = () => {
    try {
      axios
        .get(apiKey + "/get-guarantor", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setGuarantorList(res.data.result);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Guarantor_Name || "Guarantor Name",
      accessorKey: "FullName",
    },
    {
      header: lang.Identity_Number || "Identity Number",
      accessorKey: "ValidNationalID",
    },
    {
      header: lang.Occupation || "Occupation",
      accessorKey: "Occupation",
    },
    {
      header: lang.Mobile_Number || "Mobile Number",
      accessorKey: "MobilePhoneNo",
    },
    {
      header: lang.Status || "Status",
      accessorKey: "status",
      cell: (row) => <p>{row.getValue() === 1 ? "Active" : "In-active"}</p>,
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              "/dashboard/customer/profile/guarantor/" + row.row.original.id
            );
          }}
        >
          {lang.View || "View"}
        </button>
      ),
    },
  ];

  useEffect(() => {
    if(branchId){
      try {
        fetchGuarantor();
      } catch (err) {
        console.error(err);
      }
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, [])
  useEffect(() => {
    if (guarantorToDelete) {
      try {
        axios
          .put(apiKey + "/delete-guarantor", {
            idList: guarantorToDelete,
          })
          .then((res) => {
            console.log("Success");
            fetchGuarantor();
          })
          .catch((err) => {
            console.error("error");
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [guarantorToDelete]);
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Customer_Management || "Customer Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Customer_Management || "Customer Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Guarantor_List || "Guarantor List"}
              </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row justify-content-end">
                  <div className="col-md-5 col-lg-3">
                    <Link to="add-guarantor">
                      <button type="button" className="btn btn-primary">
                        <img
                          src="img/fi_plus.png"
                          alt=""
                          className="img-fluid me-2"
                        ></img>
                        {lang.Add_New_Guarantor || "Add New Guarantor"}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="rr col-9">
                    {lang.Guarantor_List || "Guarantor List"}
                  </div>
                </div>
                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3"></div>
                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      lang={lang}
                      data={guarantorList}
                      columns={columns}
                      returnTable={returnTable}
                      deleteButton={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
