import axios from "axios";
import { apiKey } from "../config/config";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { useNavigate } from "react-router-dom";

export default function ContractInfo({ lang }) {
  const [contractType, setContractType] = useState(1);
  const [customer, setCustomer] = useState();
  const [guarantor, setGuarantor] = useState([]);
  const [investor, setInvestor] = useState();
  const [quantity, setQuantity] = useState();
  const [item, setItem] = useState();
  const [unitPrice, setUnitPrice] = useState();
  const [account, setAccount] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [selectedScheduleType, setSelectedScheduleType] = useState();
  const [nextScheduledDate, setNextScheduledDate] = useState(null);
  const [contractValue, setContractValue] = useState();
  const [profit, setProfit] = useState();
  const [premium, setPremium] = useState();
  const [downPayment, setDownPayment] = useState();
  const [finalInstallment, setFinalInstallment] = useState();
  const [noInstallment, setNoInstallment] = useState();
  const [remaining, setRemaining] = useState();
  const [accountList, setAccountList] = useState([]);
  const [description, setDescription] = useState();
  const [cities, setCities] = useState([]);
  const [judgmentCity, setJudgmentCity] = useState();
  const [branchId, setBranchId] = useState();
  const [legalFees, setLegalFees] = useState();
  const [selectedCalendar, setSelectedCalendar] = useState("arabic");

  const navigate = useNavigate();

  const handleCalendarChange = (calendarType) => {
    setSelectedCalendar(calendarType);
  };

  const scheduleTypeList = [
    lang.monthly || "Monthly",
    lang.weekly || "Weekly",
    lang.halfyearly || "Half-Yearly",
    lang.yearly || "Yearly",
  ];

  const calculateNextScheduledDate = () => {
    if (!selectedScheduleType) {
      return;
    }
    // Get the current date
    const currentDate = new Date();

    // Calculate the new date based on the selected schedule type
    switch (selectedScheduleType) {
      case "Monthly":
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case "Weekly":
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case "Half-Yearly":
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case "Yearly":
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        break;
    }

    // Set the new date in the component's state
    setNextScheduledDate(currentDate);
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0]; // Formats date as 'YYYY-MM-DD'
  };

  const handleSubmit = () => {
    try {
      axios
        .post(apiKey + "/create-installment-contract", {
          contractType,
          customer,
          guarantor,
          investor,
          quantity,
          item,
          description,
          judgmentCity,
          unitPrice,
          account,
          currentDate: formatDate(new Date(currentDate)),
          selectedScheduleType,
          nextScheduledDate: formatDate(new Date(nextScheduledDate)),
          contractValue,
          profit,
          premium,
          downPayment,
          finalInstallment,
          noInstallment,
          remaining,
          branch_id: branchId,
          legal_fees: legalFees,
        })
        .then((res) => {
          setContractType("");
          setCustomer("");
          setGuarantor([]);
          setInvestor("");
          setQuantity("");
          setItem("");
          setUnitPrice("");
          setAccount("");
          setCurrentDate("");
          setSelectedScheduleType("");
          setNextScheduledDate("");
          setContractValue("");
          setProfit("");
          setPremium("");
          setDownPayment("");
          setFinalInstallment("");
          setNoInstallment("");
          setRemaining("");
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/dashboard/customer/contract-information/" + res.data.contract_id);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error Occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Error Occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (branchId) {
      axios
        .get(apiKey + "/get-accounts", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setAccountList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    const currentDateObject = new Date();

    setCurrentDate(currentDateObject);

    const basicInfo = JSON.parse(sessionStorage.getItem("formData"));
    setCustomer(basicInfo.customer);
    setGuarantor([basicInfo.guarantor1, basicInfo.guarantor2]);

    const inventory = JSON.parse(sessionStorage.getItem("formData2"));
    setInvestor(inventory.investor);
    setQuantity(inventory.quantity);
    setItem(inventory.item);
    setUnitPrice(inventory.unitPrice);
    setContractValue(Math.ceil(inventory.totalSellPrice));
    setProfit(Math.ceil(inventory.profit));
    setDescription(inventory.description);
    setPremium((20 * (inventory.profit * inventory.quantity)) / 100);
    setLegalFees(inventory.legalFees);
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
    axios
      .get(apiKey + "/get-states")
      .then((res) => {
        setCities(res.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    calculateNextScheduledDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScheduleType]);

  useEffect(() => {
    // Check if both contractValue and downPayment are defined before performing the operation
    if (contractValue && downPayment) {
      setRemaining(contractValue - downPayment);
    } else {
      // If either contractValue or downPayment is not defined, set remaining to null
      setRemaining(null);
    }
  }, [contractValue, downPayment]);

  useEffect(() => {
    // Check if remaining and noInstallment are defined before performing the operation
    if (remaining !== null && noInstallment) {
      setFinalInstallment(remaining / noInstallment);
    } else {
      // If either remaining or noInstallment is not defined, set finalInstallment to null
      setFinalInstallment(null);
    }
  }, [remaining, noInstallment]);

  useEffect(() => {
    // Ensure finalInstallment is defined before using it in the division
    if (remaining !== null && finalInstallment) {
      setNoInstallment(remaining / finalInstallment);
    } else {
      // If either remaining or finalInstallment is not defined, set noInstallment to null
      setNoInstallment(null);
    }
  }, [remaining, finalInstallment]);

  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item">
                {lang.Contracts || "Contracts"}
              </li>
              <li className="breadcrumb-item">
                {lang.New_Installment_Contracts || "New Installments Contracts"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Contract_Information || "Contract Information"}
              </li>
            </ol>
          </nav>
        </div>

        <div className="card-body pt-3 ">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <button className="nav-link">
                {lang.Basic_Information || "Basic information"}
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link ">
                {lang.Identification_of_Goods || "Identification of Goods"}
              </button>
            </li>

            <li className="nav-item">
              <button className="nav-link active">
                {lang.Contract_Information || "Contract Information"}
              </button>
            </li>
          </ul>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex flex-column-reverse flex-lg-row mt-3 mb-3 justify-content-end">
                      <label className="select-calendar mt-3 mt-lg-0">
                        <span style={{ fontSize: "14px" }}>
                          {lang.select_calendar || "Select Calendar"}:
                        </span>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="arabicRadio"
                            value="arabic"
                            checked={selectedCalendar === "arabic"}
                            onChange={() => handleCalendarChange("arabic")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="arabicRadio"
                          >
                            {lang.arabic || "Arabic"}
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="gregorianRadio"
                            value="gregorian"
                            checked={selectedCalendar === "gregorian"}
                            onChange={() => handleCalendarChange("gregorian")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="gregorianRadio"
                          >
                            {lang.gregorian || "Gregorian"}
                          </label>
                        </div>
                      </label>
                      <div className="d-flex justify-content-end">
                        <button className="btn-save">
                          {lang.Cancel || "Cancel"}
                        </button>
                        <button type="submit" className="btn-cancel ms-4">
                          {lang.Save_And_Next || "Save & Next"}
                        </button>
                      </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="contractType" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Type_of_Contract || "Type of Contract"}:
                          </span>
                          <input
                            id="contractType"
                            type="text"
                            className="form-control"
                            value={lang.Installment || "Installment"}
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="contractDate" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Date_of_Contract ||
                              "Date of Contract (YYYY/MM/DD)"}
                            :
                          </span>
                          <DatePicker
                            className="form-control w-100"
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                            }}
                            calendar={
                              selectedCalendar === "arabic" ? arabic : ""
                            }
                            locale={
                              selectedCalendar === "arabic" ? arabic_ar : ""
                            }
                            value={currentDate}
                            disabled
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="scheduleType" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Schedule_Type || "Schedule Type"}:
                          </span>
                          <select
                            id="scheduleType"
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedScheduleType}
                            onChange={(e) => {
                              setSelectedScheduleType(e.target.value);
                            }}
                            required
                          >
                            <option selected disabled></option>
                            {scheduleTypeList.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="nextScheduledDate" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Date_of_1st_Installment ||
                              "Date of 1st installment"}
                            :
                          </span>
                          <DatePicker
                            className="form-control w-100"
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                            }}
                            calendar={
                              selectedCalendar === "arabic" ? arabic : ""
                            }
                            locale={
                              selectedCalendar === "arabic" ? arabic_ar : ""
                            }
                            value={nextScheduledDate}
                            onChange={(e) => {
                              setNextScheduledDate(e);
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="contractValue" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Contract_Value || "Contract Value"}:
                          </span>
                          <input
                            id="contractValue"
                            type="text"
                            className="form-control"
                            value={contractValue + legalFees}
                            disabled
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="profit" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Profit || "Profit"}:
                          </span>
                          <input
                            id="profit"
                            type="text"
                            className="form-control"
                            value={profit}
                            disabled
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="account" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Account_for_Payments ||
                              "Account for payments"}
                            :
                          </span>
                          <select
                            id="account"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setAccount(e.target.value);
                            }}
                            required
                          >
                            <option selected disabled></option>
                            {accountList.map((account) => (
                              <option
                                key={account.id}
                                value={account.account_number}
                              >
                                {account.account_name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                      {/* <div className="col-lg-4">
                        <label htmlFor="premium" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Amount_of_Premium || "Amount of Premium"}:
                          </span>
                          <input
                            id="premium"
                            type="text"
                            className="form-control"
                            value={premium}
                            disabled
                            required
                          />
                        </label>
                      </div> */}
                      <div className="col-lg-4">
                        <label htmlFor="downPayment" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Down_Payment || "Down Payment"}:
                          </span>
                          <input
                            id="downPayment"
                            type="text"
                            className="form-control"
                            value={downPayment}
                            onChange={(e) => setDownPayment(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="remaining" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Remaining_amount || "Remaining Amount"}:
                          </span>
                          <input
                            id="remaining"
                            type="text"
                            className="form-control"
                            value={remaining}
                            disabled
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="noInstallment" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Number_of_Installments ||
                              "Number of Installments"}
                            :
                          </span>
                          <input
                            id="noInstallment"
                            type="text"
                            className="form-control"
                            value={noInstallment}
                            onChange={(e) => setNoInstallment(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="finalInstallment" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Final_Installment || "Final Installment"}:
                          </span>
                          <input
                            id="finalInstallment"
                            type="text"
                            className="form-control"
                            value={finalInstallment}
                            onChange={(e) =>
                              setFinalInstallment(e.target.value)
                            }
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.judgement_city || "Judgement City"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setJudgmentCity(e.target.value)}
                          >
                            <option selected disabled></option>
                            {cities.map((state) => (
                              <option key={state.city_id} value={state.city_id}>
                                {lang.Lang === "en"
                                  ? state.name_en
                                  : state.name_ar}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
