import axios from "axios";
import { apiKey } from "../config/config";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Profie({ lang }) {
  const [fullName, setFullName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [validID, setValidID] = useState();
  const [source, setSource] = useState();
  const [releaseDate, setReleaseDate] = useState();
  const [branchName, setBranchName] = useState();
  const [branchLocation, setBranchLocation] = useState();
  const [additional, setAdditional] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [logo, setLogo] = useState();
  const [branchId, setBranchId] = useState();

  const handlePassword = () => {
    if (passwordError) {
      toast.error("Passwords do not match", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    axios
      .put(apiKey + "/dealer-pass-update/" + branchId, {
        password: newPassword,
      })
      .then((res) => {
        setNewPassword("");
        setConfirmPassword("");
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleBasicInfo = () => {
    axios
      .put(apiKey + "/update-dealer-basic-info/" + branchId, {
        fullName,
        city,
        phoneNo,
        address,
      })
      .then((res) => {
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error("Error Occured", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleBranchInfo = () => {
    axios
      .put(apiKey + "/update-dealer-branch-info/" + branchId, {
        branchName,
        branchLocation,
        additional,
      })
      .then((res) => {
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error("Error Occured", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleLogoUpload = () => {
    if (!logo) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", logo);

    axios
      .put(`${apiKey}/dealer-logo-upload/${branchId}`, formData)
      .then((res) => {
        console.log(res);
        // Handle success if needed
      })
      .catch((err) => {
        console.error(err);
        // Handle error if needed
      });
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [confirmPassword, newPassword]);

  useEffect(() => {
    // console.log(branchId)
    axios
      .get(apiKey + "/get-dealer/" + branchId)
      .then((res) => {
        setFullName(res.data.result[0].customer_name);
        setPhoneNo(res.data.result[0].contact_number);
        setEmail(res.data.result[0].email);
        setCity(res.data.result[0].city);
        setAddress(res.data.result[0].address);
        setValidID(res.data.result[0].valid_id_number);
        setSource(res.data.result[0].source);
        setReleaseDate(res.data.result[0].release_date);
        setBranchName(res.data.result[0].branch_name);
        setBranchLocation(res.data.result[0].branch_location);
        setAdditional(res.data.result[0].additional_info);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);

  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>{lang.Profile || "Profile"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">{lang.Profile || "Profile"}</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4">{lang.Profile || "Profile"}</h4>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleBasicInfo();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" className="btn-cancel">
                        {lang.Update_Text || "Update"}
                      </button>
                    </div>

                    <div className="d-flex mt-3 mb-3">
                      <h4 class="headingh4 fw-bold">{lang.Basic_Information || "Basic Information"}</h4>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Full_Name || "Full Name"}</span>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Phone_Number || "Phone Number"}</span>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Email || "Email"}</span>
                          <input
                            type="email"
                            className="form-control"
                            style={{ cursor: "not-allowed" }}
                            required
                            disabled
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.City || "City"}</span>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Address || "Address"}</span>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            required
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          ></textarea>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                          {lang.Valid_Identity_Number || "Valid National ID"}
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            style={{ cursor: "not-allowed" }}
                            required
                            disabled
                            value={validID}
                            onChange={(e) => setValidID(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Source || "Place of issue"}</span>
                          <input
                            type="text"
                            className="form-control"
                            style={{ cursor: "not-allowed" }}
                            required
                            disabled
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Release_Date || "Release Date"}</span>
                          <input
                            type="text"
                            className="form-control"
                            style={{ cursor: "not-allowed" }}
                            required
                            disabled
                            value={releaseDate}
                            onChange={(e) => setReleaseDate(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  </form>

                  <form
                    action=""
                    onClick={(e) => {
                      e.preventDefault();
                      handleBranchInfo();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3">
                      <h4 class="headingh4 fw-bold">{lang.office_info || "Office Information"}</h4>
                    </div>
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" className="btn-cancel">
                        {lang.Update_Text || "Update"}
                      </button>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.branch_name || "Office Name"}</span>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                          {lang.branch_location || "Office Location"}
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={branchLocation}
                            onChange={(e) => setBranchLocation(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                          {lang.Additional_Notes || "Additional Information"}
                          </span>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea"
                            rows="5"
                            value={additional}
                            onChange={(e) => setAdditional(e.target.value)}
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </form>
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      handlePassword();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3">
                      <h4 class="headingh4 fw-bold">{lang.Password || "Password"}</h4>
                    </div>
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" className="btn-cancel">
                        {lang.Update_Text || "Update"}
                      </button>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.new_password || "New Password"}</span>
                          <input
                            type="password"
                            className="form-control"
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.confirm_password || "Confirm Password"}
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    {passwordError && (
                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                          <div class="alert alert-danger" role="alert">
                            Passwords do not match
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleLogoUpload();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3">
                      <h4 className="headingh4 fw-bold">{lang.logo || "Logo"}</h4>
                    </div>
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" className="btn-cancel">
                        {lang.Update_Text || "Update"}
                      </button>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.upload_logo || "Upload a logo"}
                          </span>
                          <input
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            className="form-control"
                            required
                            onChange={(e) => setLogo(e.target.files[0])}
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
