/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import axios from "axios";
import { apiKey } from "../config/config";

export default function DealerList({ lang }) {
  const [dealers, setDealers] = useState([]);

  const columns = [
    {
      header: lang.dealer_name || "Dealer Name",
      accessorKey: "customer_name",
    },
    {
      header: lang.Email || "Email",
      accessorKey: "email",
    },
    {
      header: lang.Valid_Identity_Number || "Valid Identity Number",
      accessorKey: "valid_id_number",
    },
    {
      header: lang.branch_name || "Office Name",
      accessorKey: "branch_name",
    },
    {
      header: lang.dealer_type || "Dealer Type",
      accessorKey: "dealer_type",
    },
    {
      header: lang.Status || "Status",
      accessorKey: "status",
      cell: (row) =>
        row.getValue() == 1 ? (
          <span className="badge bg-success">{lang.active || "Active"}</span>
        ) : (
          <span className="badge bg-danger">{lang.inactive || "Inactive"}</span>
        ),
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <div className="dropdown top-0 start-0">
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {lang.Actions || "Actions"}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                className="dropdown-item mt-0"
                to={"/dashboard/dealer-list/" + row.row.original.id}
              >
                {lang.change_password || "Change Password"}
              </Link>
            </li>
            <li>
              <button
                className="dropdown-item mt-0"
                onClick={() => {
                  if (row.row.original.status == 1) {
                    deactivateDealer(row.row.original.id);
                  } else {
                    activateDealer(row.row.original.id);
                  }
                }}
              >
                {row.row.original.status === 1 ? "Deactivate" : "Activate"}
              </button>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const deactivateDealer = (id) => {
    axios
      .put(`${apiKey}/deactivate-dealer/${id}`)
      .then((res) => {
        console.log(res.data.Message);
        axios
          .get(apiKey + "/get-dealers")
          .then((res) => {
            setDealers(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const activateDealer = (id) => {
    axios
      .put(`${apiKey}/activate-dealer/${id}`)
      .then((res) => {
        console.log(res.data.Message);
        axios
          .get(apiKey + "/get-dealers")
          .then((res) => {
            setDealers(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    try {
      axios
        .get(apiKey + "/get-dealers")
        .then((res) => {
          setDealers(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Dealer_Management || "Dealer Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Dealer_Management || "Dealer Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.dealer_list || "Dealer List"}
              </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3">
                      <Link to="add-new-dealer">
                        <button type="button" className="btn btn-primary ms-4">
                          <img
                            src="img/fi_plus.png"
                            alt=""
                            className="img-fluid me-2"
                          ></img>
                          {lang.add_new_dealer || "Add New Dealer"}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="rr col-9">
                      {lang.dealer_list || "Dealer List"}
                    </div>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang={lang} data={dealers} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
