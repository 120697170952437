import axios from "axios";
import React, { useState } from "react";
import { apiKey } from "../config/config";

function ShiftComponenet({ lang }) {
  const [openShiftModal, setOpenShiftModal] = useState(false);
  const [closeShiftModal, setCloseShiftModal] = useState(false);
  const [openingAmount, setOpeningAmount] = useState();
  const [shiftDetails, setShiftDetails] = useState();

  const checkShift = () => {
    const branchId = JSON.parse(
      JSON.parse(sessionStorage.getItem("aman-admin")).admin
    ).id;
    axios
      .get(apiKey + "/shift-data/" + branchId)
      .then((res) => {
        if (res.data.Message === "No Active Shift") {
          setOpenShiftModal(true);
          setCloseShiftModal(false);
        } else {
          setShiftDetails(res.data.data);
          setCloseShiftModal(true);
          setOpenShiftModal(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const closeModal = () => {
    setOpenShiftModal(false);
    setCloseShiftModal(false);
  };

  const openShift = (e) => {
    e.preventDefault();
    const branchId = JSON.parse(
      JSON.parse(sessionStorage.getItem("aman-admin")).admin
    ).id;
    axios
      .post(apiKey + "/open-shift", {
        branchId: branchId,
        openingBalance: openingAmount,
      })
      .then((res) => {
        checkShift();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const closeShift = () => {
    const branchId = JSON.parse(
      JSON.parse(sessionStorage.getItem("aman-admin")).admin
    ).id;
    axios
      .post(apiKey + "/close-shift/" + branchId, {})
      .then((res) => {
        checkShift();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <button onClick={checkShift} className="btn mx-auto mt-4 d-block">
        Check Shift
      </button>

      {openShiftModal && (
        <div className="modal-body-custom">
          <div className="modal-item-custom">
            <button
              onClick={closeModal}
              type="button"
              className="btn modal-close-btn"
            >
              <i className="fa fa-2x">×</i>
            </button>
            <h2>Open shift</h2>

            <form onSubmit={openShift}>
              <div>
                <label htmlFor="open-shift-amount">Open Shift Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="open-shift-amount"
                  value={openingAmount}
                  onChange={(e) => setOpeningAmount(e.target.value)}
                />
              </div>
              <button type="submit" className="btn-save mt-4">
                Open Shift
              </button>
            </form>
          </div>
        </div>
      )}
      {closeShiftModal && (
        <div className="modal-body-custom">
          <div className="modal-item-custom">
            <button
              onClick={closeModal}
              type="button"
              className="btn modal-close-btn"
            >
              <i className="fa fa-2x">×</i>
            </button>
            <h2>Shift Details</h2>
            <div className="">
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Opening Time
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.openingTime}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Opening Amount
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.openingBalance}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Contracts
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalContracts}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Contract Amount
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalContractAmount}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Contract Payments
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalContractPayments}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Contract Payment Amount
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalContractPaymentAmount}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Withdrawals
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalWithdrawals}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Withdrawal Amount
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalWithdrawalsAmount}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Deposits
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalDeposits}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Total Deposit Amount
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.totalDepositsAmount}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Current Balance
                </span>
                <span
                  className="text-primary"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {shiftDetails?.currentBalance}
                </span>
              </div>
            </div>
            <button
              onClick={closeShift}
              type="submit"
              className="btn-save mt-4"
            >
              Close Shift
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ShiftComponenet;
