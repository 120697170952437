import React, { useEffect, useState } from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { apiKey } from "../config/config";

export default function Accounts({ lang }) {
  const [accountList, setAccountList] = useState([]);
  const [accountName, setAccountName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [branchId, setBranchId] = useState();

  const returnTable = async (table) => {
    // await setAccountListToDelete(table.map(item => item.original.id))
  };

  const handleSubmit = () => {
    console.log("func");
    try {
      axios
        .post(`${apiKey}/add-account`, {
          account_name: accountName,
          account_number: accountNumber,
          branch_id: branchId,
        })
        .then((res) => {
          console.log(res.data.Message);
          setAccountName("");
          setAccountNumber("");
          fetchAccounts();
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Some error occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Some error occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const fetchAccounts = () => {
    try {
      axios
        .get(`${apiKey}/get-accounts`, {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setAccountList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Account_Name || "Account Name",
      accessorKey: "account_name",
    },
    {
      header: lang.Account_Number || "Account Number",
      accessorKey: "account_number",
    },
  ];

  useEffect(() => {
    if(branchId){
      fetchAccounts();
    }
  }, [branchId]);
  
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Account_List || "Account List"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Add_Account || "Add Account"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" className="btn-save">
                        {lang.Save_And_Publish || "Save & Publish"}
                      </button>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Account_Name || "Account Name"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100"></label>
                        <span style={{ fontSize: "14px" }}>
                          {lang.Account_Number || "Account Number"}:
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row pt-3">
                  <div className="rr col-9">
                    {lang.Account_List || "Accounts List"}
                  </div>
                </div>
                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3">
                    {accountList && (
                      <Table
                      lang={lang}
                        data={accountList}
                        columns={columns}
                        returnTable={returnTable}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
