// import axios from "axios";
import { apiKey } from "../config/config";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ProfitCalculator from "./ProfitCalculator";
import axios from "axios";
import ShiftComponenet from "./ShiftComponenet";

export default function SideNav({
  lang,
  currentLangFunc,
  currentDir,
  dirFormat,
}) {
  const [openNav, setOpenNav] = useState(false);
  const [userRole, setUserRole] = useState();
  const [branchId, setBranchId] = useState();
  const [logo, setLogo] = useState();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHomePath = pathname === "/";

  const handleLogout = () => {
    sessionStorage.removeItem("aman-admin");
    navigate("/");
  };

  useEffect(() => {
    if (!sessionStorage.getItem("aman-admin")) {
      navigate("/");
    } else {
      const storedUser = JSON.parse(
        JSON.parse(sessionStorage.getItem("aman-admin")).admin
      );
      if (storedUser) {
        setUserRole(storedUser.role);
      }
      setBranchId(
        JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
      );
    }

    window.scrollTo({
      top: 0,
    });
    setOpenNav(false)
  }, [navigate, pathname]);

  useEffect(() => {
    if (branchId) {
      axios
        .get(`${apiKey}/get-dealer-logo/` + branchId)
        .then((res) => {
          setLogo(res.data.branchLogo);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [branchId]);
  return (
    <>
      <ProfitCalculator lang={lang} />
      {!isHomePath && (
        <div>
          <button
            onClick={() => setOpenNav(!openNav)}
            className="btn bg-white shadow position-fixed d-lg-none"
            style={{top: "50px", zIndex: "50"}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
            </svg>
          </button>
          {openNav && <div className="navBg"></div>}
          <aside
            id="sidebar"
            className={`sidebar ${openNav ? "active" : ""} ${
              dirFormat === "rtl" ? "sidebar-right" : "sidebar-left"
            }`}
          >
            <button
              className="btn position-absolute d-lg-none"
              style={{ top: "10px", right: "10px" }}
              onClick={() => setOpenNav(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
              </svg>
            </button>
            <NavLink to="" className="logo d-flex align-items-center">
              <img src={logo || "/img/logo.png"} alt="logo" />
            </NavLink>
            <ShiftComponenet />
            <div className="w-100 d-flex justify-content-around align-items-center py-2 my-4">
              <div className="w-50 d-flex justify-content-around align-items-center">
                <div className="rounded-circle sidebar-icons">
                  <NavLink to="profile">
                    <i className="fa-regular fa-user "></i>
                  </NavLink>
                </div>
                <div
                  className="rounded-circle sidebar-icons"
                  data-bs-toggle="modal"
                  data-bs-target="#goodsverticalycentered"
                >
                  <i className="fa fa-calculator"></i>
                </div>
                <div className="rounded-circle sidebar-icons">
                  <i
                    className="fa-regular fa-bell dropdown position-static"
                    data-bs-toggle="dropdown"
                  ></i>
                  <ul className="dropdown-menu px-2">
                    <div className="row ">
                      <div className="col-12 d-flex">
                        <img
                          className="notify ps-2 pt-2"
                          src="https://reaman.taswog.com/img/notify.png"
                          alt=""
                        />
                        <p className="p ms-4">
                          <span className="fw-bold">Name </span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. <span className="date">22 DEC 7:20 PM</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <img
                          className="notify ps-2"
                          src="https://reaman.taswog.com/img/notify.png"
                          alt=""
                        />
                        <p className="p ms-4">
                          <span className="fw-bold">Name </span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. <span className="date">22 DEC 7:20 PM</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <img
                          className="notify ps-2 fs-1"
                          src="https://reaman.taswog.com/img/notify.png"
                          alt=""
                        />
                        <p className="p ms-4">
                          <span className="fw-bold">Name </span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. <span className="date">22 DEC 7:20 PM</span>
                        </p>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around align-items-center py-2 my-4">
              <div className="w-50 d-flex justify-content-around align-items-center">
                <button className="btn" onClick={() => currentLangFunc("en")}>
                  En
                </button>
                <button className="btn" onClick={() => currentLangFunc("ar")}>
                  Ar
                </button>
                <button className="btn" onClick={() => currentDir("ltr")}>
                  <svg
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 19h16v2H4zm0-4h11v2H4zm0-4h16v2H4zm0-8h16v2H4zm0 4h11v2H4z"></path>
                  </svg>
                </button>
                <button className="btn" onClick={() => currentDir("rtl")}>
                  <svg
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 19h16v2H4zm5-4h11v2H9zm-5-4h16v2H4zm0-8h16v2H4zm5 4h11v2H9z"></path>
                  </svg>
                </button>
              </div>
            </div>

            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="dashboard"
                  end
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/dashboard.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Dashboard || "Dashboard"}</span>
                </NavLink>
              </li>

              <li className="nav-heading">
                {lang.Customer_Management || "Customer Management"}
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="customer"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/customers.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Customers || "Customers"}</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="customer-manager"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/Guarantors.png"
                    alt="logo"
                    className="sidebaricon"
                  />

                  <span>{lang.Guarantors || "Guarantors"}</span>
                </NavLink>
              </li>

              <li className="nav-heading">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="investor-report"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/investorlist.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Investors_List || "Investor List"}</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="investment-pool"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img src="/img/pool.png" alt="logo" className="sidebaricon" />
                  <span>{lang.Investment_Pools || "Investment Pools"}</span>
                </NavLink>
              </li>

              <li className="nav-heading">
                {lang.item_management || "Item Management"}
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="inventory-category"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/inventory.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Inventory_Category || "Item Category"}</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="inventory-system"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/inventory.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Inventory || "Item"}</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="inventory"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/inventory.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>
                    {lang.Purchase_Inventory_List || "Purchase Item List"}
                  </span>
                </NavLink>
              </li>

              {/* <li className="nav-item">
              <NavLink
                className="nav-link collapsed"
                to="loan-avail-list"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#0F60FF" : "#000000",
                    background: isActive ? "#f6f9ff" : "",
                  };
                }}
              >
                <img
                  src="/img/loanavail.png"
                  alt="logo"
                  className="sidebaricon"
                />
                <span>{lang.Loan_Request_List || "Loan Reqeust List"}</span>
              </NavLink>
            </li> */}

              {/* <li className="nav-item">
              <NavLink
                className="nav-link collapsed"
                to="type-of-loan"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#0F60FF" : "#000000",
                    background: isActive ? "#f6f9ff" : "",
                  };
                }}
              >
                <img
                  src="/img/typepool.png"
                  alt="logo"
                  className="sidebaricon"
                />
                <span>{lang.Type_of_Loans || "Type Of Loans"}</span>
              </NavLink>
            </li> */}

              <li className="nav-heading">
                {lang.General_Management || "General Management"}
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="accounts"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/contracts.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Accounts || "Accounts"}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="expenses"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/contracts.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Expenses || "Expenses"}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="contracts"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/contracts.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Contracts || "Contracts"}</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="advance-options"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <svg
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                    <path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path>
                  </svg>
                  <span>{lang.Advance_Settings || "Advance Settings"}</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link collapsed"
                  to="report"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#0F60FF" : "#000000",
                      background: isActive ? "#f6f9ff" : "",
                    };
                  }}
                >
                  <img
                    src="/img/report.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Reports || "Reports"}</span>
                </NavLink>
              </li>

              {/* <li className="nav-item">
              <div
                className="nav-link collapsed"
                to="zakat"
                // style={({ isActive }) => {
                //   return {
                //     color: isActive ? "#0F60FF" : "#000000",
                //     background: isActive ? "#f6f9ff" : "",
                //   };
                // }}
              >
                <img src="/img/zakat.png" alt="logo" className="sidebaricon" />
                <span>{lang.Zakat || "Zakat"}</span>
              </div>
            </li> */}

              {userRole === "super" && (
                <>
                  <li className="nav-heading">
                    {lang.Dealer_Management || "Dealer Management"}
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link collapsed"
                      to="dealer-list"
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#0F60FF" : "#000000",
                          background: isActive ? "#f6f9ff" : "",
                        };
                      }}
                    >
                      <img
                        src="/img/dealer.png"
                        alt="logo"
                        className="sidebaricon"
                      />
                      <span>{lang.Dealers || "Dealers"}</span>
                    </NavLink>
                  </li>
                </>
              )}
              <li className="nav-item">
                <button
                  className="nav-link collapsed w-100"
                  onClick={handleLogout}
                >
                  <img
                    src="/img/dealer.png"
                    alt="logo"
                    className="sidebaricon"
                  />
                  <span>{lang.Logout || "Logout"}</span>
                </button>
              </li>
              {/* <li className="nav-item">
              <button
                className="nav-link collapsed"
                onClick={() => currentLangFunc("ar")}
              >
                <img src="/img/dealer.png" alt="logo" className="sidebaricon" />
                <span>{"Change Language"}</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link collapsed"
                onClick={() => currentLangFunc("en")}
              >
                <img src="/img/dealer.png" alt="logo" className="sidebaricon" />
                <span>{"Change Language English"}</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link collapsed"
                onClick={() => currentDir("rtl")}
              >
                <img src="/img/dealer.png" alt="logo" className="sidebaricon" />
                <span>{"Change Dir English"}</span>
              </button>
            </li> */}
              {/* currentDir */}
            </ul>
          </aside>
        </div>
      )}
    </>
  );
}
