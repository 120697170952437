import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiKey } from "../config/config";
import { Link } from "react-router-dom";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";

export default function InventoryLoanList({ lang }) {
  const [loanList, setLoanList] = useState([]);
  const [branchId, setBranchId] = useState();

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Customer_Name || "Customer Name",
      accessorKey: "FullName",
    },
    {
      header: lang.Loan_Type || "Loan Type",
      accessorKey: "request_type",
    },
    {
      header: lang.Mobile_Number || "Mobile Number",
      accessorKey: "MobilePhoneNo",
    },
    {
      header: lang.Requested_Amount || "Requested Amount",
      accessorKey: "loan_request_amount",
    },
    {
      header: lang.Status || "",
      accessorKey: "loan_request_status",
    },
  ];

  useEffect(() => {
    try {
      axios
        .get(apiKey + "/get-loan-request", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setLoanList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Inventory_Loan_List || "Inventory Loan List"}
              </li>
            </ol>
          </nav>
        </div>

        <div className="card-body pt-3">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <Link to="/dashboard/loan-avail-list">
                <button className="nav-link  ">
                  {lang.All_Available_Loan_List || "All Avalable Loan List"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/collection-and-pay">
                <button className="nav-link ">
                  {lang.Collections_and_Payments || "Collections and Payments"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/installment-and-pay">
                <button className="nav-link ">
                  {lang.Installments_and_Payments ||
                    "Installments and payments"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/inventory-loan">
                <button className="nav-link active">
                  {lang.Inventory_Loan_List || "Inventory Loan List"}
                </button>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/dashboard/return">
                <button className="nav-link ">
                  {" "}
                  {lang.Returns || "Returns"}{" "}
                </button>
              </Link>
            </li>
          </ul>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row pt-3 pb-3">
                    <div className="rr col-9">
                      {lang.Inventory_Loan_List || "Inventory Loan List"}
                    </div>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang={lang} data={loanList} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
