/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiKey } from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
// import DatePicker from "react-multi-date-picker";
// import arabic from "react-date-object/calendars/arabic";
// import arabic_ar from "react-date-object/locales/arabic_ar";

export default function ValidCon({ lang }) {
  // const [selectedCalendar, setSelectedCalendar] = useState("arabic");
  // const handleCalendarChange = (calendarType) => {
  //   setSelectedCalendar(calendarType);
  // };
  // const [contractInfo, setContractInfo] = useState({
  //   id: "",
  //   status: "",
  //   dueDate: "",
  // });
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const formatDate = (date) => {
  //   return date.toISOString().split("T")[0]; // Formats date as 'YYYY-MM-DD'
  // };
  // const handleUpdateDate = (e) => {
  //   e.preventDefault();

  //   axios
  //     .put(
  //       apiKey + "/updateContractDate/" + contractInfo.id,
  //       contractInfo
  //     )
  //     .then((res) => {
  //       console.log(res.data.Message);
  //       fetchContracts();
  //       toast.success(res.data.Message, {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       toast.error("Error Occured", {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };

  const [contractList, setContractList] = useState([]);
  const [contractData, setContractData] = useState({
    id: "",
    total_value: "",
    total_paid: "",
    final_installment: "",
  });
  const [branchId, setBranchId] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [payment, setPayment] = useState();
  const [discount, setDiscount] = useState();
  const [receipts, setReceipts] = useState([]);
  const [receiptData, setReceiptData] = useState({
    FullName: "",
    Address: "",
    MobilePhoneNo: "",
    Email: "",
    branch_name: "",
    branch_location: "",
    contact_number: "",
    email: "",
    date: "",
    reference_number: "",
    amount: "",
    paymentMethod: "",
  });

  const navigate = useNavigate();

  function convertDateFormat(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    const locale = lang.Lang === "en" ? "en-US" : "ar-SA";

    const formattedDate = dateObject.toLocaleDateString(locale, options);
    return formattedDate;
  }

  const handlePayment = () => {
    axios
      .put(apiKey + "/get-contract-to-update/" + contractData.id, {
        payment: payment,
        discount,
        paymentMethod,
        user_id: contractData.customer_id,
        branch_id: branchId,
      })
      .then((res) => {
        fetchContracts();
        if (res.data.contract && (res.data.contract[0].status == 2)) {
          navigate(`/dashboard/contracts/valid-contract/complete-contract/${res.data.contract[0].id}`);
          window.location.reload();
        }
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error Occured", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const fetchContracts = () => {
    try {
      axios
        .get(apiKey + "/get-contracts", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setContractList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchReceipts = (id) => {
    try {
      axios
        .get(apiKey + "/get-receipts/" + id)
        .then((res) => {
          console.log(res.data.result);
          setReceipts(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      header: lang.Clients || "Clients",
      accessorKey: "customer_name",
    },
    {
      header: lang.Amount || "Amount",
      cell: (row) => (
        <p>{row.row.original.total_value - row.row.original.profit}</p>
      ),
    },
    {
      header: lang.Profit || "Profit",
      accessorKey: "profit",
    },
    {
      header: lang.legal_fees || "Legal Fees",
      accessorKey: "legal_fees",
    },
    {
      header: lang.Total || "Total",
      accessorKey: "total_value",
      cell: row => row.row.original.total_value + row.row.original.legal_fees
    },
    {
      header: lang.Paid_Up || "Paid up",
      accessorKey: "total_paid",
    },
    {
      header: lang.Residual || "Residual",
      cell: (row) => (
        <p>{row.row.original.total_value - row.row.original.total_paid}</p>
      ),
    },
    {
      header: lang.due_date || "Due Date",
      accessorKey: "due_date",
      cell: (row) => convertDateFormat(row.getValue()),
    },
    {
      header: lang.Status || "Status",
      accessorKey: "status",
      cell: (row) => (
        <p
          class={`badge rounded-pill ${
            row.getValue() == 1
              ? "bg-success"
              : row.getValue() == 2
              ? "bg-primary"
              : row.getValue() == 3
              ? "bg-danger"
              : ""
          }`}
        >
          {row.getValue() == 1
            ? lang.in_progress || "in-progress"
            : row.getValue() == 2
            ? lang.completed || "Completed"
            : row.getValue() == 3
            ? lang.defaulted || "Defaulted"
            : ""}
        </p>
      ),
    },
    {
      header: lang.payment_status || "Payment Status",
      accessorKey: "payment_status",
      cell: (row) => (
        <p
          class={`badge rounded-pill ${
            row.getValue() == 1
              ? "bg-warning"
              : row.getValue() == 2
              ? "bg-success"
              : row.getValue() == 3
              ? "bg-primary"
              : ""
          }`}
        >
          {row.getValue() == 1
            ? lang.pending || "Pending"
            : row.getValue() == 2
            ? lang.due || "Due"
            : row.getValue() == 3
            ? lang.completed || "Completed"
            : ""}
        </p>
      ),
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <div className="dropdown top-0 start-0">
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {lang.Actions || "Actions"}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button
                data-bs-toggle="modal"
                data-bs-target="#Comodity"
                type="button"
                onClick={() => setContractData(row.row.original)}
                className="dropdown-item mt-0"
              >
                {lang.add_payment || "Add Payment"}
              </button>
            </li>
            <li>
              <button
                data-bs-toggle="modal"
                data-bs-target="#myModal"
                type="button"
                className="dropdown-item mt-0"
                onClick={(e) => {
                  e.preventDefault();
                  fetchReceipts(row.row.original.id);
                }}
              >
                {lang.view_payment || "View Payments"}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  navigate(
                    `/dashboard/contracts/valid-contract/complete-contract/${row.row.original.id}`
                  )
                }
                className="dropdown-item mt-0"
                disabled={row.row.original.status != 2}
              >
                {lang.complete_contract || "Complete Contract"}
              </button>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  useEffect(() => {
    try {
      if (branchId) {
        fetchContracts();
      }
    } catch (err) {
      console.error(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  useEffect(() => {
    setPayment(
      contractData.final_installment <
        contractData.total_value - contractData.total_paid - (discount || 0)
        ? contractData.final_installment
        : contractData.total_value - contractData.total_paid - (discount || 0)
    );
  }, [contractData, discount]);

  useEffect(() => {
    setDiscount(contractData.discount || 0)
  }, [contractData])

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Valid_Contract || "Valid Contract"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3">
                      <div></div>
                      {/* <Link to="add-loan-contract">
                        <button
                          type="button"
                          className="btn btn-primary d-flex justify-content-end ms-2"
                        >
                          <img
                            src="img/fi_plus.png"
                            alt=""
                            className="img-fluid me-2"
                          />{" "}
                          {lang.Add_New_Contract || "Add  New Contract"}
                        </button>
                      </Link> */}
                    </div>
                  </div>

                  <div className="row pt-3 pb-3">
                    <div className="rr col-9">
                      {lang.List_of_Valid_Contracts || "List Of Valid Contract"}
                    </div>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang data={contractList} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* add payment */}
        <div className="modal fade" id="Comodity" tabindex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePayment();
                  }}
                >
                  <div className="row mb-2 mt-5 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Total_Value || "Total Value"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={contractData.total_value + contractData.legal_fees}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Remaining || "Remaining"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            (contractData.total_value + contractData.legal_fees) - contractData.total_paid
                          }
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.legal_fees || "Legal Fees"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            contractData.legal_fees
                          }
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.additional_discount || "Additional Discount"}
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Remaining || "Remaining"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            (contractData.total_value) - contractData.total_paid - (discount || 0)
                          }
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.payment || "Payment"}
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          max={
                            contractData.total_value - contractData.total_paid
                          }
                          value={payment}
                          onChange={(e) => setPayment(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.payment_method || "Payment Method"}
                        </span>
                        <select
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          className="form-control"
                          name=""
                          id=""
                          required
                        >
                          <option value="" disabled selected></option>
                          <option value="Cash">{lang.cash || "Cash"}</option>
                          <option value="Bank Transfer">
                            {lang.bank_transfer || "Bank Transfer"}
                          </option>
                          <option value="Card">{lang.card || "Card"}</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="text-center mb-5">
                    <button
                      type="button"
                      className="btn-save"
                      data-bs-dismiss="modal"
                    >
                      {lang.Cancel || "Cancel"}
                    </button>
                    <button type="submit" className="btn-cancel ms-4">
                      {lang.Add_Now || "Add Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Payments */}
        <div className="modal fade in" id="myModal" tabindex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  {lang.view_payment || "View Payments"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                >
                  <i className="fa fa-2x">×</i>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table
                    id="CompTable"
                    className="table table-bordered table-hover table-striped"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>{lang.date || "Date"}</th>
                        <th style={{ width: "30%" }}>
                          {lang.reference_no || "Reference No"}
                        </th>
                        <th style={{ width: "15%" }}>
                          {lang.amount || "Amount"}
                        </th>
                        <th style={{ width: "15%" }}>
                          {lang.paid_by || "Paid by"}
                        </th>
                        <th style={{ width: "10%" }}>
                          {lang.Actions || "Actions"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {receipts.map((receipt, index) => (
                        <tr className="row1" key={index}>
                          <td>{convertDateFormat(receipt.date)}</td>
                          <td>{receipt.reference_number}</td>
                          <td>{receipt.amount}</td>
                          <td>{receipt.payment_method}</td>
                          <td>
                            <div className="text-center">
                              <button
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setReceiptData(receipt);
                                }}
                              >
                                {lang.receipt || "Receipt"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Reciepts */}
        <div className="modal fade in" id="myModal1" tabindex="-1">
          <div className="modal-content modal-dialog">
            <div className="modal-body print position-relative">
              <button
                type="button"
                className="close"
                data-bs-toggle="modal"
                data-bs-target="#myModal1"
              >
                <i className="fa fa-2x">×</i>
              </button>
              <button
                type="button"
                // onClick={() => toPDF()}
                onClick={handlePrint}
                className="btn btn-primary position-absolute top-0 end-0"
                style={{ margin: "1rem 1rem" }}
              >
                {lang.print_reciept || "Print Receipt"}
              </button>
              <div
                id="receipt-pdf"
                className="p-4"
                dir={lang.Lang === "ar" ? "rtl" : "ltr"}
                // ref={targetRef}
                ref={componentRef}
              >
                <div className="row padding10">
                  <div className="col-xs-5">
                    {lang.to_text || "To"}:
                    <br />
                    <h2 className="">{receiptData.branch_name}</h2>
                    {/* Supplier Address */}
                    <br />
                    {receiptData.branch_location}
                    <br />
                    {lang.tel || "Tel"}: {receiptData.contact_number}
                    <br />
                    {lang.Email || "Email"}: {receiptData.email}
                  </div>
                  <div className="col-xs-5">
                    <br />
                    {lang.from_text || "From"}:
                    <br />
                    <h2 className="">{receiptData.FullName}</h2>
                    <p>{receiptData.Address}</p>
                    <p></p>
                    {lang.tel || "Tel"}: {receiptData.MobilePhoneNo}
                    <br />
                    {lang.Email || "Email"}: {receiptData.Email}{" "}
                    <div className="clearfix"></div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <p style={{ fontWeight: "bold" }}>
                      {lang.date || "Date"}:{" "}
                      {convertDateFormat(receiptData.date)}
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      {lang.reference_no || "Reference No"}:{" "}
                      {receiptData.reference_number}
                    </p>
                  </div>
                  <p>&nbsp;</p>

                  <div style={{ clear: "both" }}></div>
                </div>
                <div className="well">
                  <table
                    className="table table-borderless"
                    style={{ marginBottom: "0" }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <strong>{lang.payment_sent || "Payment Sent"}</strong>
                        </td>
                        <td className="text-right">
                          <strong className="text-right">
                            {receiptData.amount}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{lang.paid_by || "Paid by"}</strong>
                        </td>
                        <td className="text-right">
                          <strong className="text-right">
                            {receiptData.payment_method}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ clear: "both" }}></div>
                <div className="row">
                  <div className="col-sm-4 pull-left">
                    <p>&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>&nbsp;</p>

                    <p style={{ borderBottom: "1px solid #666" }}>&nbsp;</p>

                    <p>{lang.stamp_and_sign || "Stamp & Signature"}</p>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
